<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage sliders</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Slider
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    v-if="checkIsAccessible('slider', 'create')"
                    @click="createSliderType()"
                    x-large
                    text
                  >
                    Slider type
                  </v-btn>
                  <v-btn
                    @click="createSlider()"
                    v-if="checkIsAccessible('slider', 'create')"
                    class="mt-1 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    slider
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="getSlider"
                    outlined
                    v-model="search.title"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="getSlider"
                    v-model="search.active"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="text-right" md="4">
                  <button class="btn btn-primary w-35" @click="getSlider">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </button>
                </v-col>

              </v-row>
            </div>
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th
                      class="px-3"
                      style="max-width: 270px !important; white-space: pre-wrap"
                    >
                      Image
                    </th>
                    <th class="px-3">Title</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <draggable
                  v-show="sliders.length > 0"
                  @change="sort"
                  v-model="sliders"
                  class="text-left"
                  tag="tbody"
                >
                  <tr v-for="(slider, index) of sliders" :key="index">
                    <td class="px-3 wrap-column">
                      <i class="cursor-drag fas fa-sort handle pr-2"></i>

                      <img
                        v-if="slider.image_path.thumb"
                        :src="slider.image_path['thumb']"
                        alt
                        style="height: 75px"
                        class="img-thumbnail"
                      />
                    </td>
                    <td class="px-3 wrap-column">
                      <a href="#" @click="editSlider(slider.id)" class="mr-2">
                        {{ slider.title }}</a
                      >&nbsp;&nbsp;
                      <i
                        class="fas fa-circle"
                        :class="slider.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>

                    <td class="px-2 text-center">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('slider', 'edit')"
                          >
                            <a href="#" class="navi-link" @click="editSlider(slider.id)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('slider', 'delete')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteSlider(slider.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="sliders.length == 0">
                  <td colspan="3" class="text-center">
                    <strong>
                    No data available to display.
                  </strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                v-if="sliders.length > 0"
                class="pull-right mt-7"
                @input="getSlider"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                :loading="loading"
                first-number
                last-number
              ></b-pagination>
            </div>
            <div
              class="wizard wizard-2"
              id="kt_wizard_v2"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                <div class="row">
                  <form
                    @submit.prevent="getSlider"
                    class="row mt-5 mb-5 bg-filter"
                  ></form>
                  <div class="col-12 mt-2"></div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <!--  -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import SliderService from "@/core/services/slider/SliderService";
import draggable from "vuedraggable";

const slider = new SliderService();
export default {
  name: "sliders",
  display: "Table",
  order: 8,
  components: { draggable },
  data() {
    return {
      sliders: [],
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      page: null,
      total: null,
      perPage: null,
      search: {
        active: "active",
      },
      loading: false,
    };
  },
  mounted() {
    this.getSlider();
  },
  methods: {
    createSlider() {
      this.$router.push({
        name: "slider-new",
      });
    },
    createSliderType() {
      this.$router.push({
        name: "slider-type",
      });
    },
    getSlider() {
      this.loading = true;
      slider.paginate(this.search, this.page).then((response) => {
        this.sliders = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      });
    },
    editSlider(sliderId) {
      this.$router.push({ name: "slider-update", params: { id: sliderId } });
    },
    sort() {
      slider
        .sort(this.sliders)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getSlider();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    deleteSlider(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            slider
              .delete(id)
              .then((response) => {
                this.$snotify.success("Information deleted");

                this.getSlider();
              })
              .catch((error) => {});
          }
        },
      });
    },
  },
};
</script>
